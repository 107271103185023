var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"8","offset-md":"2","offset-lg":"3"}},[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"text-center text-h4"},[_vm._v(" How do I upgrade or Downgrade my DStv Package? ")])])],1),_c('v-card',[_c('v-row',[_c('p',{staticClass:"px-10 pt-4"},[_vm._v(" There are three methods to follow to upgrade or downgrade your DStv Package. ")]),_c('v-col',{staticClass:"px-10",attrs:{"cols":"12"}},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 1. Upgrade/Downgrade Package Online using your DStv Account ")]),_c('v-expansion-panel-content',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.oneIcon))]),_vm._v(" Login to your DStv online account "),_c('router-link',{attrs:{"to":{
                                                        query: {
                                                            method: 'online',
                                                        },
                                                        name: _vm.$constants
                                                            .ROUTE_NAMES
                                                            .FAQ_HOW_DO_I_LOGIN_TO_MY_DSTV_ACCOUNT,
                                                    }}},[_vm._v("as explained here")]),_vm._v(". ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.twoIcon))]),_vm._v(" Tap on the "),_c('code',[_vm._v("Change package")]),_vm._v(" On your dstv account the dashboard ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"300","contain":"","src":require("@/assets/img/faq/dstv-change-package-1.jpg")}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.threeIcon))]),_vm._v(" Select the package you want to upgrade on downgrade your DStv account. ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"300","contain":"","src":require("@/assets/img/faq/dstv-change-package-2.jpg")}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"success","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.fourIcon))]),_vm._v(" Tap "),_c('code',[_vm._v("Next")]),_vm._v(" and your dstv package will be changed instantly! ")],1)])],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 2. Upgrade/Downgrade Package Using the MyDstv App ")]),_c('v-expansion-panel-content',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.oneIcon))]),_vm._v(" Login to the MyDSTV app "),_c('router-link',{attrs:{"to":{
                                                        query: {
                                                            method: 'app',
                                                        },
                                                        name: _vm.$constants
                                                            .ROUTE_NAMES
                                                            .FAQ_HOW_DO_I_LOGIN_TO_MY_DSTV_ACCOUNT,
                                                    }}},[_vm._v("as explained here")]),_vm._v(". ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.twoIcon))]),_vm._v(" Tap on "),_c('code',[_vm._v("view all packages")]),_vm._v(". Tap on your current package, and the "),_c('code',[_vm._v("Change package now")]),_vm._v(" button will appear. ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.threeIcon))]),_vm._v(" Tap on "),_c('code',[_vm._v("Change Package Now")]),_vm._v(", Chose the new package you want to upgrade/downgrade to and tap on "),_c('code',[_vm._v("Next")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"success","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.fourIcon))]),_vm._v(" You will see a success message, which indicates that your account has been upgraded or downgraded. ")],1)])],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 3. Upgrade/Downgrade Package Using DStv WhatsApp bot. ")]),_c('v-expansion-panel-content',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.oneIcon))]),_vm._v(" Login to your DStv account using whatsapp "),_c('router-link',{attrs:{"to":{
                                                        query: {
                                                            method: 'whatsapp',
                                                        },
                                                        name: _vm.$constants
                                                            .ROUTE_NAMES
                                                            .FAQ_HOW_DO_I_LOGIN_TO_MY_DSTV_ACCOUNT,
                                                    }}},[_vm._v("as explained here")]),_vm._v(". ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.twoIcon))]),_vm._v(" On the DSTV whatsapp chat, reply with "),_c('code',[_vm._v("0")]),_vm._v(" and Choose "),_c('code',[_vm._v("Change Package")]),_vm._v(" from the options presented. "),_c('br'),_c('em',[_vm._v(" (option 4 in this cse)")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"300","contain":"","src":require("@/assets/img/faq/dstv-change-package-3.jpg")}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.threeIcon))]),_vm._v(" Choose the package you want to upgrade/downgrade to. "),_c('br'),_c('em',[_vm._v("Make sure you always reply by entering the number against the option you wish to access.")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"300","contain":"","src":require("@/assets/img/faq/dstv-change-package-4.jpg")}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"success","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.fourIcon))]),_vm._v(" Choose yes to confirm. ")],1)])],1)],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-10"},[_c('back-button',{staticClass:"mx-auto"})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }